import type {
  Collection,
  AccessibleEntity,
  CollectionResult,
  EntityAccessEntity,
  GatingConditionEntity,
  GatingConditionParams,
  GatingStrategies,
  GatingTarget,
  MessageResult,
  PostGatingBody,
  Result,
  EditGatingBody,
} from "../types";
import axios from "../core/axios";
import { assertSuccess, paramsParser } from "../core";
import { Headers } from "./generic";

export class Gating {
  static async getConditions(
    entityType: GatingTarget,
    entityId: string,
    params: Omit<GatingConditionParams, "entityId" | "entityType"> = {},
    auth?: string,
  ): Promise<Collection<GatingConditionEntity>> {
    const res = await axios.get<CollectionResult<GatingConditionEntity>>(
      `/${entityType}/${entityId}/gating/condition` + paramsParser(params),
      {
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(res.data);
  }

  static async createCondition<
    T extends GatingStrategies,
    E extends GatingTarget,
  >(entityType: E, body: PostGatingBody<T, E>, auth: string) {
    const res = await axios.post<Result<GatingConditionEntity<T>>>(
      `/${entityType}/${body[`${entityType}Id`]}/gating/condition`,
      body,
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(res.data).data;
  }

  static async editCondition<
    T extends GatingStrategies,
    E extends GatingTarget,
  >(
    entityType: E,
    conditionId: string,
    body: EditGatingBody<T, E>,
    version: number,
    auth: string,
  ) {
    const res = await axios.post<Result<GatingConditionEntity<T>>>(
      `/${entityType}/${
        body[`${entityType}Id`]
      }/gating/condition/${conditionId}`,
      {
        ...body,
        version,
      },
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(res.data).data;
  }

  static async deleteCondition<E extends GatingTarget>(
    entityType: E,
    entityId: string,
    conditionId: string,
    version: number,
    auth: string,
  ) {
    const res = await axios.delete<MessageResult<string>>(
      `/${entityType}/${entityId}/gating/condition/${conditionId}?version=${version}`,
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(res.data);
  }

  static async deleteTitleCondition(
    titleId: string,
    conditionId: string,
    version: number,
    auth: string,
  ) {
    return Gating.deleteCondition("title", titleId, conditionId, version, auth);
  }

  static async deletePostCondition(
    postId: string,
    conditionId: string,
    version: number,
    auth: string,
  ) {
    return Gating.deleteCondition("post", postId, conditionId, version, auth);
  }

  static async editTitleCondition<T extends GatingStrategies>(
    conditionId: string,
    body: EditGatingBody<T, "title">,
    version: number,
    auth: string,
  ) {
    return await Gating.editCondition(
      "title",
      conditionId,
      body,
      version,
      auth,
    );
  }

  static async editPostCondition<T extends GatingStrategies>(
    conditionId: string,
    body: EditGatingBody<T, "post">,
    version: number,
    auth: string,
  ) {
    return await Gating.editCondition("post", conditionId, body, version, auth);
  }

  static async createTitleCondition<T extends GatingStrategies>(
    body: PostGatingBody<T, "title">,
    auth: string,
  ) {
    return await Gating.createCondition("title", body, auth);
  }

  static async createPostCondition<T extends GatingStrategies>(
    body: PostGatingBody<T, "post">,
    auth: string,
  ) {
    return await Gating.createCondition("post", body, auth);
  }

  static async checkEntityAccess(
    entities: {
      entityType: AccessibleEntity["type"];
      entityId: NonNullable<GatingConditionParams["entityId"]>;
    }[],
    auth?: string,
  ) {
    const res = await axios.post<Result<EntityAccessEntity>>(
      "/gating/check",
      {
        entities,
      },
      {
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(res.data).data;
  }
}
